
import shuffle from 'lodash/shuffle';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import ProductsSlider from 'src/components/ProductsSlider/ProductsSlider';
import Button from 'src/ui-kit/Button';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { formatPrice } from 'src/utils/formatPrice';
import getChainLink from 'src/utils/getChainLink';
import getChainPromoLink from 'src/utils/getChainPromoLink';
import getCustomBadge from 'src/utils/getCustomBadge';
import { Product } from '../../../api/types';
import useLang from '../../../services/language/useLang';
import { useLocalize } from '../../../services/localize/useLocalize';
import ProductTile from '../../ProductTile';
import SeeMoreCard from '../../SeeMoreCard';

interface Props {
  products: Product[];
  chain: string;
  logo: string;
  hostname: string;
  isFetching: boolean;
}

function MainProductsPromotionSlider(props: Props) {
  const lang = useLang();
  const { products, chain, logo, hostname, isFetching } = props;
  const [productsToBeShown, setProductsToBeShown] = useState(products);
  const localize = useLocalize();

  useEffect(() => {
    if(products.length % 5 === 0) {
      setProductsToBeShown(shuffle(products.slice(0, -1)));
    }
  },[products]);

  const seeMoreLink = getChainPromoLink(hostname, lang);

  return (
    <StylishBox sbs={{ pt: '40px' }}>
      <ProductsSlider
        isFetching={isFetching}
        buttonSlot={
          <Button
            variant="secondarySmall"
            width="190px"
            onClick={() => window.open(seeMoreLink, '_blank')}
            dataMarker='show more'
          >
            {localize('all_promotions')}
          </Button>
        }
        titleSlot={
          <StylishBox
            sbs={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <a href={getChainLink(hostname, lang)} target="_blank" data-testid = 'Chain link' rel='noreferrer'>
              <Image
                src={logo}
                alt={chain}
                width={124}
                height={40}
              />
            </a>
          </StylishBox>
        }
      >
        {productsToBeShown.map(p => (
          <ProductTile
            key={p.ean}
            title={p.title}
            img={p.img.s350x350}
            weight={p.weight}
            unit={p.unit}
            volume={p.volume}
            price={formatPrice(p.price)}
            oldPrice={formatPrice(p.discount.old_price)}
            isHit={p.is_hit}
            discount={p.discount.value}
            webUrl={p.web_url}
            customBadge={getCustomBadge(chain, p.custom_ribbons)}
          />
        ))}

        <SeeMoreCard
          linkText={localize('all_promotions')}
          linkHref={seeMoreLink}
        />
      </ProductsSlider>
    </StylishBox>
  );
}

export default MainProductsPromotionSlider;
