import cn from 'classnames';
import shuffle from 'lodash/shuffle';
import { useMemo } from 'react';
import { useChains } from '../api/swr';
import { RetailChain } from '../api/types';
import useLang from '../services/language/useLang';
import { useLocalize } from '../services/localize/useLocalize';
import { tablet } from '../styles/media';
import DoubleHeading from './DoubleHeading';
import StorePromotionsSection from './StorePromotionsSection/StorePromotionsSection';

interface Props {
  chains: RetailChain[];
  extendedPadding: boolean;
}

function PromotionSection(props: Props) {
  const lang = useLang();
  const { chains } = useChains({ fallbackData: props.chains, language: lang });
  const localize = useLocalize();

  const shuffledChains = useMemo(() => shuffle(chains), [chains]);

  return (
    <section
      className={cn('PromotionSection', {
          'PromotionSection_extendedPadding': props.extendedPadding,
        },
      )}
      data-marker="Promotion Section"
    >
      <DoubleHeading
        smallHeading={localize('choose_profitably')}
        bigHeading={localize('promotional_items')}
      />

      {
        shuffledChains.map(chain =>
          <StorePromotionsSection
            key={chain.id}
            chain={chain.id}
            logo={chain.logo.logo}
            lang={lang}
            hostname={chain.hostname}
          />,
        )
      }

      <style jsx>{`
        .PromotionSection {
          padding-top: 56px;
        }

        .PromotionSection_extendedPadding {
          padding-top: 137px;
        }

        @media ${tablet} {
          .PromotionSection {
            padding: 80px 0 0 0;
          }

          .PromotionSection_extendedPadding {
            padding-top: 145px;
          }

        }
      `}</style>
    </section>
  );
}

export default PromotionSection;
