import css from 'styled-jsx/css';
import { desktop, phone, tablet, tablet_desktop } from '../../styles/media';

const getStyles = theme => {
  return css`
    .StorePromotionsSection {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
    .StorePromotionsSection__storeButtonContainer {
      padding: 32px 16px 56px;
    }
    .StorePromotionsSection__storeButtonContainer {
      display: flex;
      justify-content: center;
    }
    .StorePromotionsSection__container:nth-child(even) { //
      background-color: ${theme.colors.white};
    }
    .StorePromotionsSection__container:nth-child(odd) {//
      background-color: ${theme.colors.solitude};
    }
    .StorePromotionsSection__storeButtonMobile {
      display: flex;
      padding: 32px 16px 56px 0px;
      justify-content: center;
    }
    .StorePromotionsSection__info {
      display: flex;
      justify-content: center;
    }

    @media ${phone} {
      .StorePromotionsSection {
        padding-left: 16px;
      }
    }

    @media ${tablet} {
      .StorePromotionsSection__storeButtonMobile {
        width: 225px;
        margin: 0 auto;
        padding: 32px 0 0;
      }
      .StorePromotionsSection {
        padding-bottom: 80px;
        padding-left: 33px;
      }
    }

    @media ${tablet_desktop} {
      .StorePromotionsSection__storeButton {
        width: 190px;
        display: flex;
      }
    }

    @media ${desktop} {
      .StorePromotionsSection {
        padding-bottom: 95px;
      }

      .StorePromotionsSection__buttonContainer {
        display: flex;
        position: absolute;
        right: 0;
        padding-left: 0;
        padding-right: 0;
        align-self: center;
      }
      .StorePromotionsSection__storeButtonMobile {
        display: none;
      }
    }
  `;
};

export default getStyles;
