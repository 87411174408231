import { Fragment, useState } from 'react';
import { useProducts } from '../../api/useProducts';
import { useLocalize } from '../../services/localize/useLocalize';
import getChainPromoLink from '../../utils/getChainPromoLink';
import CenteringComponent from "../CenteringComponent";
import Impressionable from '../Impressionable';
import MainProductsPromotionSlider from './MainProductsPromotionSlider/MainProductsPromotionSlider';
import getStyles from './StorePromotionsSection.styles';
import Button from 'src/ui-kit/Button';
import useThemeOld from 'src/services/theme/useThemeOld';

interface Props {
  chain: string;
  lang: string;
  logo: string;
  hostname: string;
}

function StorePromotionsSection(props: Props) {
  const { chain, lang, logo, hostname } = props;
  const [isImpressed, setIsImpressed] = useState(false);
  const localize = useLocalize();
  const theme = useThemeOld();
  const styles = getStyles(theme);
  const { products, productsIsFetching } = useProducts(isImpressed, chain, lang);


  return (
    <Impressionable
      partialVisibility
      onImpressed={() => setIsImpressed(true)}
    >
      <Fragment>
        {(!isImpressed || productsIsFetching || !!products.length) && (
          <div className='StorePromotionsSection__container'>
            <CenteringComponent>
              <section className='StorePromotionsSection'>
                <MainProductsPromotionSlider
                  products={products}
                  isFetching={productsIsFetching || !isImpressed}
                  chain={chain}
                  logo={logo}
                  hostname={hostname}
                />
                <div className="StorePromotionsSection__storeButtonMobile">
                  <Button
                    width="100%"
                    variant='secondary'
                    onClick={() => window.open(getChainPromoLink(hostname, lang), '_blank')}
                  >
                    {localize('all_promotions')}
                  </Button>
                </div>
              </section>
            </CenteringComponent>

            <style jsx>{styles}</style>
          </div>
        )}
      </Fragment>
    </Impressionable>
  );
}

export default StorePromotionsSection;
